<template>
  <div id="address-selection">
    <div id="geocoder"></div>
  </div>
</template>

<script>
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export default {
  name: 'AddressSelection',
  data: function () {
    return {
      geocoder: null,
      results: null,
    }
  },
  destroyed() {
    console.log("destroyed adress selector");
  },
  mounted() {
    console.log("mounted adress selector");
    this.geocoder = new MapboxGeocoder({
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      types: 'postcode,place,address',
    });

    this.geocoder.addTo('#geocoder');
    this.geocoder.on('result', function (e) {
      this.selectedLocation({'coords': {'latitude': e.result.center[1], 'longitude': e.result.center[0]}})
    }.bind(this));
    
    // Clear results container when search is cleared.
    this.geocoder.on('clear', function () {
      this.results = null;
    });
    this.scrollToElement({ block: 'end', scrollBehavior: 'smooth' })
  },
  methods: {
    selectedLocation (coordinates) {
      console.log("selected loc: ", coordinates);
      this.$emit('addressSelected', coordinates);      
    },
  },
}
</script>

<style lang="scss">


#address-selection {
  #geocoder {
    z-index: 1;
    // width: 100%;

    .mapboxgl-ctrl-geocoder {
      min-width: 60%;
      margin: 0 auto; 
      padding: 0 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .mapboxgl-ctrl-geocoder--icon-search {
        position: static;
      }
      .mapboxgl-ctrl-geocoder--input {
        text-align: left;
        color: #000;
        background-color: transparent;
        font-family: Assistant;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 130% */
        height: 74px;
        width: 100%;
        min-width: 450px;
        padding: 0;
        outline:none;
        text-overflow: ellipsis;
        border-style: none;
        &::placeholder {
          color: black;
          font-style: italic;
          font-family: Assistant;
          text-overflow: ellipsis;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 130% */
        }
      }

      .mapboxgl-ctrl-geocoder--pin-right {
        button {
          top: 22px;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  #address-selection {
    #geocoder {
      .mapboxgl-ctrl-geocoder {
        .mapboxgl-ctrl-geocoder--input {
          padding: 0 15px;
          min-width: 100%;

        }
      }
    }
  }
}

</style>
